import React from "react";
import { LoginForm as CommonLoginForm, AuthService } from "tds_shared_ui";
import { WithCommonTranslations, WithUser, WithRegion } from "./../hocs";
import { TranslationMixin, withRouter } from "tds_shared_ui";
import { TermsAndConditions } from "../UI";
import { TermsService } from "../../services";

export const LoginForm = WithRegion(
  withRouter(
    WithUser(
      WithCommonTranslations(
        class LoginForm extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.state = {
              showTerms: false,
              userStored: null,
            };

            this.handleSuccess = this.handleSuccess.bind(this);
            this.handleFailure = this.handleFailure.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
            this.handleTermsSuccess = this.handleTermsSuccess.bind(this);
            this.onUnload = this.onUnload.bind(this);
          }

          componentDidMount() {
            this.handleStaticContent();
            window.addEventListener("beforeunload", this.onUnload);
          }

          componentDidUpdate(prevProps) {
            if (prevProps.locale !== this.props.locale) {
              this.handleStaticContent();
            }
          }

          componentWillUnmount() {
            window.removeEventListener("beforeunload", this.onUnload);
          }

          onUnload() {
            //clear authtoken that's set at login if terms still open (e.g. user hit page refresh)
            if (this.state.showTerms) {
              this.clearAuthToken();
            }
          }

          clearAuthToken() {
            AuthService.clearAuthToken();
            AuthService.removeAuthTokenHeader();
          }

          handleSuccess(user) {
            this.setState(
              {
                showTerms: true,
                userStored: user,
              },
              this.props.onShowTerms
            );
          }

          handleTermsSuccess() {
            this.setState({
              showTerms: false,
            });

            this.props.setUser(this.state.userStored);
            const location = this.props.location;

            if (location.state && location.state.redirect) {
              this.props.navigate(location.state.redirect);
            } else if (
              this.props.location.pathname === "/registration" ||
              this.props.location.pathname === "/login"
            ) {
              this.props.navigate("/");
            } else {
              this.hideTerms();
            }
          }

          handleFailure(error) {
            this.props.setUser(null);
            if (error.legacyUserKey) {
              //hide login pop-up
              if (this.props.onClick) {
                this.props.onClick();
              }
              let data = {
                username: error.username,
                legacyUserKey: error.legacyUserKey,
              };
              let url = error.status === 418 ? "/updateuser" : "/legacyuser";

              //redirect to legacy user page to update profile
              this.props.navigate(url, data);
            } else {
              this.setState({
                isAuthenticating: false,
                alert: {
                  display: true,
                  message:
                    error.translatedMessage ||
                    this.getTranslation("ERROR_GENERIC"),
                },
              });
            }
          }

          handleCancel() {
            //clear authtoken that's set at login if they didn't accept terms
            this.clearAuthToken();
            this.setState({
              showTerms: false,
            });
            this.hideTerms();
            this.props.setUser(null);
          }

          hideTerms() {
            if (this.props.onHideTerms) {
              this.props.onHideTerms();
            }
          }

          handleStaticContent() {
            const tdstermsconditions =
              this.props.regionData.regionCode.toUpperCase() === "AU"
                ? "tdstermsconditionsau"
                : "tdstermsconditions";
            TermsService.getTerms(tdstermsconditions, this.props.locale).then(
              (data) => {
                this.setState({
                  termsData: data.content,
                });
              }
            ).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          render() {
            const { onClick, locale } = this.props;
            return (
              <div>
                <CommonLoginForm
                  onLoginFailure={this.handleFailure}
                  onLoginSuccess={this.handleSuccess}
                  registrationLink="/registration"
                  forgotPasswordLink="/resetpassword"
                  onLinkClick={onClick}
                  locale={locale}
                />

                <TermsAndConditions
                  onSuccess={this.handleTermsSuccess}
                  open={this.state.showTerms}
                  onCancel={this.handleCancel}
                  text={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.termsData,
                      }}
                    ></div>
                  }
                />
              </div>
            );
          }
        }
      )
    )
  )
);

import React from "react";
import { Header, Table, LoadingIcon, Button, Alert } from "../UI";
import { VinSubscriptions } from "./VinSubscriptions";
import { SubscriptionService } from "../../services";
import { TranslationMixin } from "tds_shared_ui";
import {
  WithRegion,
  WithSubscriptionsTranslations,
  WithCommonTranslations,
} from "../hocs/";
import { RenewSubscriptionModal } from "./RenewSubscriptionModal";
import { AlertMixin, HelperUtils, Modal } from "tds_shared_ui";

import "./Subscriptions.css";

export const MySubscriptions = WithRegion(
  WithSubscriptionsTranslations(
    WithCommonTranslations(
      class MySubscriptions extends AlertMixin(
        TranslationMixin(React.Component)
      ) {
        constructor(props) {
          super(props);

          this.alertRef = React.createRef();
          this.cancelToken = SubscriptionService.getCancelToken().source();

          this.state = {
            loading: false,
            showVinSubscriptions: false,
            showRenewSubscriptions: false,
            renewSubscriptionData: null,
            showConfirmationModal: false,
            mySubData: null,
            backupRowData: null,
            alert: {
              display: false,
              type: "default",
              message: "",
            },
          };

          this.handleViewVinClick = this.handleViewVinClick.bind(this);
          this.handleCancel = this.handleCancel.bind(this);
          this.handleViewRenewSubscriptions =
            this.handleViewRenewSubscriptions.bind(this);
          this.handleCloseRenewSubscriptions =
            this.handleCloseRenewSubscriptions.bind(this);
          this.handlePurchaseClick = this.handlePurchaseClick.bind(this);
          this.handleAccessSubscription =
            this.handleAccessSubscription.bind(this);
          this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
        }

        scrollToAlert() {
          HelperUtils.scrollToAlert(this.alertRef);
        }

        handleViewVinClick() {
          this.clearError();
          this.setState({ showVinSubscriptions: true });
        }

        handleCancel() {
          this.setState({
            showVinSubscriptions: false,
          });
        }

        handleConfirmCancel() {
          this.setState({
            showConfirmationModal: false,
          });
        }

        handleCloseRenewSubscriptions() {
          this.setState({
            showRenewSubscriptions: false,
            showConfirmationModal: false,
          });
        }

        handleViewRenewSubscriptions(rowData) {
          this.clearError();
          if (rowData === undefined && this.state.backupRowData !== null) {
            rowData = this.state.backupRowData;
          }
          const { id } = rowData;
          let subInfoArray = this.props.allSubscriptions.filter(
            (sub) => sub.id === id
          );
          let subInfo = subInfoArray.length > 0 ? subInfoArray[0] : null;

          if (
            subInfo &&
            subInfo.name === "Techline Connect" &&
            !this.state.showConfirmationModal
          ) {
            this.state.mySubData.forEach((subscription) => {
              if (
                subscription.id !== "TLC-COMP" &&
                subscription.status === "Active"
              ) {
                this.setState({
                  showConfirmationModal: true,
                  backupRowData: rowData,
                });
              } else if (subscription.id === "TLC-COMP" &&
                subscription.status === "Active"
              ) {
                this.setState({
                  showConfirmationModal: true,
                  backupRowData: rowData,
                });
              } else if (subscription.id === "TLC-COMP" &&
                subscription.status !== "Active"
              ) {
                this.setState({
                  showRenewSubscriptions: true,
                  renewSubscriptionData: subInfo,
                });
              }
            });
          } else if (subInfo) {
            this.setState({
              showRenewSubscriptions: true,
              renewSubscriptionData: subInfo,
            });
          } else {
            this.displayAlert(
              this.getTranslation("ERROR_UNABLE_TO_RENEW"),
              "error",
              this.scrollToAlert
            );
          }
        }

        handlePurchaseClick(item, productId) {
          return this.props.onPurchaseClick(item, productId).then(() => {
            this.setState({
              showRenewSubscriptions: false,
              renewSubscriptionData: null,
            });
          }).catch((e) => {

          });
        }

        handleAccessSubscription(vinData) {
          this.clearError();
          return SubscriptionService.getAccessSubscriptionData(vinData.id)
            .then((html) => {
              let win = window.open("", "");
              if (win === null) {
                this.displayAlert(
                  this.getTranslation("ALERT_TURN_OFF_POPUP"),
                  "warning",
                  this.scrollToAlert
                );
              } else {
                win.document.write(html);
                win.document.redirectForm.submit();
              }
            })
            .catch((e) => this.displayError(e, this.scrollToAlert));
        }

        componentDidMount() {
          this.setState({ loading: true });
          SubscriptionService.getMySubscriptionData({
            cancelToken: this.cancelToken.token,
          })
            .then((d) => {
              let { message, subscriptions } = d;
              if (message) {
                this.displayAlert(message, "warning", this.scrollToAlert);
              }
              this.setState({ mySubData: subscriptions, loading: false });
            })
            .catch((e) => {
              if (!e.unmounted) {
                this.setState({ loading: false });
                this.displayError(e, this.scrollToAlert);
              }
            });
        }

        componentWillUnmount() {
          if (this.cancelToken) {
            this.cancelToken.cancel({ unmounted: true });
          }
        }

        render() {
          const { mySubData } = this.state;

          const cols = [
            {
              header: this.getTranslation("LABEL_STATUS"),
              accessor: "statusDesc",
            },
            {
              header: this.getTranslation("LABEL_SUBSCRIPTION_NAME"),
              accessor: "productName",
            },
            {
              header: this.getTranslation("LABEL_DESCRIPTION"),
              accessor: "productDesc",
            },
            {
              header: this.getTranslation("LABEL_ACTIONS"),
              accessor: "status",
              render: (rowData) => {
                if (rowData.status === "Expired") {
                  if (rowData.purchasable) {
                    let btnLabel =
                      rowData.productType === "DOWNLOAD"
                        ? "BUTTON_PURCHASE"
                        : "MESSAGE_RENEW";
                    return (
                      <Button
                        variant="link"
                        onClick={() =>
                          this.handleViewRenewSubscriptions(rowData)
                        }
                      >
                        {this.getTranslation(btnLabel)}
                      </Button>
                    );
                  }
                } else if (rowData.productType === "DOWNLOAD") {
                  return (
                    <Button
                      variant="link"
                      onClick={() => this.handleAccessSubscription(rowData)}
                    >
                      {this.getTranslation("MESSAGE_DOWNLOAD")}
                    </Button>
                  );
                } else if (rowData.status === "Active") {
                  let buttons = [];
                  buttons.push(
                    <span key="access">
                      <Button
                        variant="link"
                        onClick={() => this.handleAccessSubscription(rowData)}
                      >
                        {this.getTranslation("MESSAGE_ACCESS")}
                      </Button>
                    </span>
                  );

                  if (rowData.purchasable) {
                    buttons.push(
                      <React.Fragment key="extend">
                        &nbsp;|{" "}
                        <span>
                          <Button
                            variant="link"
                            onClick={() =>
                              this.handleViewRenewSubscriptions(rowData)
                            }
                          >
                            {this.getTranslation("MESSAGE_EXTEND")}
                          </Button>
                        </span>
                      </React.Fragment>
                    );
                  }
                  return buttons;
                } else if (rowData.productType === "VIN") {
                  return (
                    <Button variant="link" onClick={this.handleViewVinClick}>
                      {this.getTranslation("LABEL_VIEW")}
                    </Button>
                  );
                }
              },
            },
          ];

          return (
            <div className="mySubscriptions">
              <div>
                <Header level={3} className="itemHeader">
                  {this.getTranslation("HEADER_MY_SUBSCRIPTIONS")}
                </Header>
                <p className="text-gray">
                  {this.getTranslation("MESSAGES_MY_SUBSCRIPTIONS")}
                </p>
              </div>

              {this.state.alert.display && (
                <Alert
                  ref={this.alertRef}
                  type={this.state.alert.type}
                  className="mb-0"
                >
                  {this.state.alert.message}
                </Alert>
              )}

              {this.state.loading ? (
                <LoadingIcon
                  display={this.state.loading}
                  text={this.getTranslation("MESSAGE_LOADING")}
                />
              ) : (
                <Table columns={cols} rows={mySubData} filterId="mySubFilter" />
              )}

              <VinSubscriptions
                open={this.state.showVinSubscriptions}
                onCancel={this.handleCancel}
              />

              <RenewSubscriptionModal
                open={this.state.showRenewSubscriptions}
                onClose={this.handleCloseRenewSubscriptions}
                onPurchaseClick={this.handlePurchaseClick}
                subscriptionInfo={this.state.renewSubscriptionData}
              />

              <Modal
                className="addToCartConfirmModal"
                header={
                  <Header level={1}>
                    {this.getTranslation("LABEL_WARNING")}
                  </Header>
                }
                onClose={this.handleConfirmCancel}
                open={this.state.showConfirmationModal}
                onBackdropClick={this.state.close}
              >
                <div className="container pr-0 pl-0">
                  <div>
                    <p>
                      {this.getTranslation("MESSAGE_TLC_SUBSCRIPTION_CONFLICT")}
                    </p>
                  </div>
                  <div className="float-right">
                    <Button
                      type="button"
                      variant="link"
                      onClick={this.handleConfirmCancel}
                    >
                      {this.getTranslation("BUTTON_CANCEL")}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={this.handleViewRenewSubscriptions}
                    >
                      {this.getTranslation("BUTTON_CONFIRM")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          );
        }
      }
    )
  )
);

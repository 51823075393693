import React from "react";
import { Header, LoadingIcon, Select, Button } from "../UI";
import { TranslationMixin, List, Modal } from "tds_shared_ui";
import { NimbusLink as Link } from "tds_shared_ui";
import {
  WithSubscriptionsTranslations,
  WithCommonTranslations,
  WithUser,
} from "../hocs/";
import { WithRegion } from "../hocs";
import "./Subscriptions.css";
import { SubscriptionService } from "../../services";

export const SubscriptionsItem = WithRegion(
  WithUser(
    WithSubscriptionsTranslations(
      WithCommonTranslations(
        class SubscriptionsItem extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.cancelToken = SubscriptionService.getCancelToken().source();

            this.state = {
              selectedValue: null,
              showCartConfirmation: false,
              showSPSWarnCartConfirmation: false,
              loading: false,
              hasTisSubscription: false,
              error: false,
            };
            this.handleClick = this.handleClick.bind(this);
            this.handleSelect = this.handleSelect.bind(this);
            this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
            this.handleKeycodeButton = this.handleKeycodeButton.bind(this);
          }

          handleClick() {
            let showSPSWarn = false;

            switch (this.props.subscriptionType.id) {
              case "TIS-DIAG1TD":
                showSPSWarn = true;
                break;
              case "TLC-NA-T2DIAG":
                showSPSWarn = true;
                break;
              default:
            }

            if (this.state.selectedValue === null) {
              this.setState({
                error: true,
              });
            } else if (
              this.props.subscriptionType.name === "Techline Connect" &&
              !this.state.showCartConfirmation &&
              this.state.hasTisSubscription
            ) {
              this.setState({
                showCartConfirmation: true,
              });
            } else if (showSPSWarn && !this.state.showSPSWarnCartConfirmation) {
              this.setState({
                showSPSWarnCartConfirmation: true,
              });
            } else {
              this.setState({
                loading: true,
                error: false,
                showCartConfirmation: false,
              });
              this.props
                .onClick(
                  this.state.selectedValue,
                  this.props.subscriptionType.id
                )
                .catch(() => {
                  this.resetSelectedValue();
                  this.setState({
                    loading: false,
                  });
                });
            }
          }

          handleKeycodeButton() {
            this.setState({
              loading: true,
              error: false,
              showCartConfirmation: false,
            });
            this.props.onClick(
              this.state.selectedValue,
              this.props.subscriptionType.id
            );
          }

          handleSelect(value) {
            this.setState({
              selectedValue: value,
            });
          }

          componentDidMount() {
            this.resetSelectedValue();
            this.checkIfUserContainsTisSubscription();
          }

          resetSelectedValue() {
            const { subscriptionType } = this.props;
            let selectedValue = null;
            if (subscriptionType.skus.length === 1) {
              selectedValue = subscriptionType.skus[0].id;
            }

            this.setState({
              selectedValue: selectedValue,
            });
          }

          checkIfUserContainsTisSubscription() {
            /*Check only if subscription item is TLC and user is logged in*/
            if (
              this.props.subscriptionType.name === "Techline Connect" &&
              this.props.user
            ) {
              this.setState({ loading: true });
              SubscriptionService.getMySubscriptionData({
                cancelToken: this.cancelToken.token,
              })
                .then((d) => {
                  let { subscriptions } = d;
                  subscriptions.forEach((subscription) => {
                    if (
                      subscription.id !== "TLC-COMP" &&
                      subscription.status === "Active"
                    ) {
                      this.setState({ hasTisSubscription: true });
                    }
                  });
                  this.setState({ mySubData: subscriptions, loading: false });
                })
                .catch((e) => {
                  if (!e.unmounted) {
                    this.setState({ loading: false });
                  }
                });
            }
          }

          handleConfirmCancel() {
            this.setState({
              showCartConfirmation: false,
              showSPSWarnCartConfirmation: false,
            });
          }

          render() {
            const { subscriptionType, className } = this.props;
            const selectOptions = [];
            const nonRefundable = subscriptionType.id === "TLC-NA-T2DIAG"; // TODO remove this and add new column to table
            for (let selectOption of subscriptionType.skus) {
              selectOptions.push({
                label: selectOption.name,
                value: selectOption.id,
              });
            }
            const showKeyCodeButton =
              subscriptionType.id.toUpperCase() === "EKC-AU";

            //HIDE GM SUBSCRIPTION FOR EU
            if (
              subscriptionType.id === "GM" &&
              (this.props.regionData.regionCode === "EU" ||
                this.props.regionData.regionCode === "CH")
            ) {
              return (
                <div className={"itemSubscriptions " + (className || "")}>
                  <div className="row" id={subscriptionType.id}>
                    <div className="col-md-5"></div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={"itemSubscriptions " + (className || "")}>
                  {/*Subscription Items*/}
                  <div className="row" id={subscriptionType.id}>
                    <div className="col-md-5">
                      <span className="font-weight-bold">
                        {subscriptionType.name}
                      </span>{" "}
                      {subscriptionType.subName === null ? (
                        ""
                      ) : (
                        <i className={nonRefundable ? "text-red" : ""}>
                          ({subscriptionType.subName})
                        </i>
                      )}
                      {subscriptionType.descriptions &&
                        subscriptionType.descriptions.map(
                          (itemDescriptions, index) => (
                            <div className="text-gray" key={index}>
                              {itemDescriptions}
                            </div>
                          )
                        )}
                      {subscriptionType.link === null ? (
                        ""
                      ) : (
                        <Link
                          external
                          href={subscriptionType.link.url}
                          target="_blank"
                        >
                          {subscriptionType.link.title}
                        </Link>
                      )}
                    </div>

                    <List
                      className={
                        "align-self-center " +
                        (subscriptionType.skus.length > 1
                          ? "list__item col-md-2"
                          : "list__item col-sm-6 col-md-2 offset-md-3")
                      }
                    >
                      {subscriptionType.skuDescriptions.map(
                        (skuItem, index) => (
                          <div className="p-0" key={index}>
                            {skuItem}
                          </div>
                        )
                      )}
                    </List>

                    {subscriptionType.skus.length > 1 ? (
                      <div className="col-sm-6 col-md-3 col-lg-2 offset-lg-1 text-gray pb-0 pt-0 align-self-center">
                        <Select
                          label={this.getTranslation("LABEL_SELECT")}
                          options={selectOptions}
                          removable
                          value={this.state.selectedValue}
                          onChange={this.handleSelect}
                          error={this.state.error && !this.state.selectedValue}
                          errorMessage={this.getTranslation("ERROR_REQUIRED")}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {!showKeyCodeButton ? (
                      <div className="col-sm-6 col-md-2 align-self-center">
                        <Button
                          type="button"
                          variant="primary"
                          className="w-100"
                          onClick={this.handleClick}
                          disabled={
                            subscriptionType.purchasable === false ||
                            this.state.loading
                          }
                        >
                          <LoadingIcon
                            display={this.props.loading || this.state.loading}
                          />
                          {this.getTranslation("BUTTON_ADD_TO_CART")}
                        </Button>
                        {subscriptionType.nonPurchasableReason && (
                          <div className="fa-sm mt-2 mb-2">
                            <p>
                              <i>{subscriptionType.nonPurchasableReason}</i>
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {showKeyCodeButton ? (
                      <div className="col-sm-6 col-md-2 align-self-center">
                        <Button
                          type="button"
                          variant="primary"
                          className="w-100"
                          onClick={this.handleKeycodeButton}
                          disabled={
                            subscriptionType.purchasable === false ||
                            this.state.loading
                          }
                        >
                          <LoadingIcon
                            display={this.props.loading || this.state.loading}
                          />
                          {this.getTranslation("BUTTON_LINK_TO_KEYCODES")}
                        </Button>
                        {subscriptionType.nonPurchasableReason && (
                          <div className="fa-sm mt-2 mb-2">
                            <p>
                              <i>{subscriptionType.nonPurchasableReason}</i>
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <Modal
                    className="addToCartConfirmModal"
                    header={
                      <Header level={1}>
                        {this.getTranslation("LABEL_WARNING")}
                      </Header>
                    }
                    onClose={this.handleConfirmCancel}
                    open={this.state.showCartConfirmation}
                    onBackdropClick={this.state.onClose}
                  >
                    <div className="container pr-0 pl-0">
                      <div>
                        <p>
                          {this.getTranslation(
                            "MESSAGE_TLC_SUBSCRIPTION_CONFLICT"
                          )}
                        </p>
                      </div>
                      <div className="float-right">
                        <Button
                          type="button"
                          variant="link"
                          onClick={this.handleConfirmCancel}
                        >
                          {this.getTranslation("BUTTON_CANCEL")}
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          onClick={this.handleClick}
                        >
                          {this.getTranslation("BUTTON_CONFIRM")}
                        </Button>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    className="addToCartConfirmModal"
                    header={
                      <Header level={1}>
                        {this.getTranslation("LABEL_WARNING")}
                      </Header>
                    }
                    onClose={this.handleConfirmCancel}
                    open={this.state.showSPSWarnCartConfirmation}
                    onBackdropClick={this.state.onClose}
                  >
                    <div className="container pr-0 pl-0">
                      <div>
                        <p>
                          <span style={{ color: "red" }}>
                            {this.getTranslation("NOT_SPS_WARNING")}
                          </span>
                        </p>
                      </div>
                      <div className="float-right">
                        <Button
                          type="button"
                          variant="link"
                          onClick={this.handleConfirmCancel}
                        >
                          {this.getTranslation("BUTTON_CANCEL")}
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          onClick={this.handleClick}
                        >
                          {this.getTranslation("BUTTON_CONFIRM")}
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </div>
              );
            }
          }
        }
      )
    )
  )
);

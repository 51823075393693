import React from "react";
import { ContactUsForm } from "../components/ContactUs/ContactUsForm";
import { ContactUsService, HelperUtils, CaptchaService } from "../services";
import {
  WithContactTranslations,
  WithCommonTranslations,
} from "../components/hocs";
import {
  TranslationMixin,
  AlertMixin,
  Alert,
  withRouter,
} from "tds_shared_ui";

export const ContactUsPage = withRouter(
  WithCommonTranslations(
  WithContactTranslations(
    class extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.alertRef = React.createRef();
        this.captchaRef = React.createRef();

        this.state = {
          loading: false,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
          contactName: "",
          contactPhone: "",
          contactEmail: "",
          comment: "",
        };

        this.onValidationError = this.onValidationError.bind(this);
      }

      handleCancel = () => {
        this.props.navigate(-1);
      };

      handleChange = (name, value, callback) => {
        this.clearError();

        this.setState(
          {
            [name]: value,
          },
          callback
        );
      };

      getCommentObject(formValues) {
        let { contactName, contactPhone, contactEmail, comment } = formValues;

        const commentObj = {
          name: contactName,
          phone: contactPhone,
          email: contactEmail,
          comment: comment,
        };
        return commentObj;
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      handleSubmit = () => {
        let captchaData = CaptchaService.getCaptchaData(this.captchaRef);

        if (!captchaData.cText) {
          this.displayAlert(
            this.getTranslation("ERROR_CAPTCHA_MUST_BE_COMPLETED"),
            "error",
            this.scrollToAlert
          );
          return;
        }

        this.setState({
          loading: true,
          alert: {
            display: false,
          },
        });

        let commentObj = this.getCommentObject(this.state);
        commentObj = Object.assign(commentObj, captchaData);
        ContactUsService.submitComment(commentObj)
          .then(() => {
            CaptchaService.refreshCaptcha(this.captchaRef);
            this.setState(
              {
                loading: false,
                readOnly: true,
                contactName: "",
                contactPhone: "",
                contactEmail: "",
                comment: "",
                alert: {
                  display: true,
                  message: this.getTranslation("MESSAGE_SUCCESS"),
                  type: "success",
                },
              },
              this.scrollToAlert
            );
          })
          .catch((e) => {
            this.displayError(e, this.scrollToAlert);
            this.setState({
              loading: false,
            });
          });
      };

      onValidationError() {
        this.setState(
          {
            alert: {
              display: true,
              message: this.getTranslation("ERROR_FORM_VALIDATION"),
              type: "error",
            },
          },
          this.scrollToAlert
        );
      }

      render() {
        return (
          <div className="contactUsPage">
            {this.state.alert.display && (
              <Alert ref={this.alertRef} type={this.state.alert.type}>
                {this.state.alert.message}
              </Alert>
            )}
            <ContactUsForm
              submit={this.handleSubmit}
              captchaRef={this.captchaRef}
              onValidationError={this.onValidationError}
              formValues={this.state}
              onChange={this.handleChange}
              onCancel={this.handleCancel}
              loading={this.state.loading}
            />
          </div>
        );
      }
    }
  )
)
);

import React, { Component } from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import { AppHeader } from "./containers/Header/AppHeader";
import { AppFooter } from "./containers/Footer/AppFooter";
import { SubscriptionsPage } from "./containers/SubscriptionsPage";
import { StorePage } from "./containers/StorePage";
import { RegistrationPage } from "./containers/RegistrationPage";
import { ResetPasswordPage } from "tds_shared_ui";
import { ProfilePage } from "./containers/ProfilePage";
import { Alert } from "./components/UI";
import { ContactUsPage } from "./containers/ContactUsPage";
import { KeycodePage } from "./containers/KeycodePage";
import { ChangePasswordPage } from "./containers/ChangePasswordPage";
import { MyOrdersPage } from "./containers/MyOrdersPage";
import { CheckoutPage } from "./containers/CheckoutPage";
import { ShoppingCartPage } from "./containers/ShoppingCartPage";
import { HelpPage } from "./containers/HelpPage";
import { TermsAndConditionsPage } from "./containers/TermsAndConditionsPage";
import { EulaPage } from "./containers/EulaPage";
import { LoginPage } from "./containers/LoginPage";
import { LegacyProfilePage } from "./containers/LegacyProfilePage";
import { EmailUpdateProfilePage } from "./containers/EmailUpdateProfilePage";
import { RpoLookupPage } from "./containers/RpoLookupPage";
import NotFound from "./containers/NotFound";

import {
  CheckoutTranslationsProvider,
  ContactTranslationsProvider,
  KeyCodeTranslationsProvider,
  LinksConsumer,
  PasswordTranslationsProvider,
  RegionConsumer,
  SubscriptionsTranslationsProvider,
  UserConsumer,
  UserTranslationsProvider,
} from "./context";
import { AuthService } from "./services";
import { WithUser } from "./components/hocs";

import { CommonTranslationsConsumer, withRouter } from "tds_shared_ui";
import "./App.css";


export const AppRouter = withRouter(
  WithUser(
    class AppRouter extends Component {
      constructor(props) {
        super(props);

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
      }

      componentDidMount() {
        AuthService.handleUnauthorizedErrors((error) => {
          this.props.setUser(null);
          // Redirect to login page if not already there unless it's the accessSPSCal call
            // or the VIN RPO lookup call
          if (
            this.props.location.pathname !== "/login" &&
            !error.config.url.includes("accessSPSCal") &&
            !error.config.url.includes("accessRPOLookup")
          ) {
            this.props.navigate("/login", {
              redirect: this.props.location,
            });
          }
        });
      }

      handleLanguageChange(langCode) {
        this.props.onLanguageChange(langCode);
      }

      render() {
        const { locale } = this.props;
        return (
          <div className="App">
            <LinksConsumer>
              {({ resourceLinks }) => (
                <AppHeader
                  resourceLinks={resourceLinks}
                  locale={this.props.locale}
                  onLocaleChange={this.handleLanguageChange}
                />
              )}
            </LinksConsumer>
            <div className="container">
              <Routes>
                <Route path="/" element={<Navigate to="/subscriptions" replace={true} />} />
                <Route
                  path="/subscriptions"
                  element={
                    <SubscriptionsTranslationsProvider locale={locale}>
                      <SubscriptionsPage locale={locale} />
                    </SubscriptionsTranslationsProvider>
                  }
                />

                <Route
                  path="/login"
                  element={ <LoginPage locale={locale} />}
                />

                <Route path="/keycodes" element={
                  <RegionalRoute
                  validRegion={(regionData) => regionData.regionConfigs.keycodeAvailable === "true"}
                  locale={locale}
                  render={(props) => (
                    <KeyCodeTranslationsProvider locale={locale}>
                      <KeycodePage {...props} locale={locale} />
                    </KeyCodeTranslationsProvider>
                  )}
                />}
                />

                <Route path="/myorders" element={
                <PrivateRoute
                  locale={locale}
                  render={(props) => (
                        <KeyCodeTranslationsProvider locale={locale}>
                          <MyOrdersPage {...props} />
                        </KeyCodeTranslationsProvider>
                  )}
                />}
                />

                <Route
                  path="/legacyuser"
                  locale={locale}
                  element={
                    <UserTranslationsProvider locale={locale}>
                      <LegacyProfilePage  locale={locale} />
                    </UserTranslationsProvider>
                  }
                />
                <Route
                  path="/updateuser"
                  locale={locale}
                  element={
                    <UserTranslationsProvider locale={locale}>
                      <EmailUpdateProfilePage locale={locale} />
                    </UserTranslationsProvider>
                  }
                />
                <Route
                  path="/store"
                  element={
                    <RegionConsumer>
                      {({ regionData }) =>
                        regionData !== null &&
                        regionData.regionConfigs.storeLink ? (
                          <StorePage />
                        ) : (
                          <CommonTranslationsConsumer locale={locale}>
                            {({ commonTranslations }) => (
                              <Alert type="error">
                                {commonTranslations
                                  ? commonTranslations[
                                      "ERROR_REGION_UNAUTHORIZED_PAGE"
                                    ]
                                  : ""}
                              </Alert>
                            )}
                          </CommonTranslationsConsumer>
                        )
                      }
                    </RegionConsumer>
                  }
                />

                <Route
                  path="/shoppingcart"
                  element={
                    <ShoppingCartPage locale={locale} />
                  }
                />

                <Route
                  path="/checkout"
                  element={
                    <CheckoutTranslationsProvider locale={locale}>
                      <CheckoutPage />
                    </CheckoutTranslationsProvider>
                  }
                />

                <Route
                  path="/help"
                  element={ <HelpPage locale={locale} />}
                />

                <Route
                  path="/terms"
                  element={
                    <TermsAndConditionsPage locale={locale} />
                  }
                />

                <Route
                  path="/eula"
                  element={<EulaPage locale={locale} />}
                />

                <Route
                  path="/registration"
                  element={
                    <UserConsumer>
                      {({ user }) =>
                        user !== null ? (
                          <Redirect to="/" />
                        ) : (
                          <UserTranslationsProvider locale={locale}>
                            <RegistrationPage locale={locale} />
                          </UserTranslationsProvider>
                        )
                      }
                    </UserConsumer>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <PrivateRoute
                      locale={locale}
                      render={(props) => (
                        <UserTranslationsProvider locale={locale}>
                          <ProfilePage {...props} />
                        </UserTranslationsProvider>
                      )}
                    />
                  }
                />

                <Route
                  path="/resetpassword"
                  element={
                    <PasswordTranslationsProvider locale={locale}>
                      <ResetPasswordPage locale={locale} />
                    </PasswordTranslationsProvider>
                  }
                />

                <Route
                  path="/contactus"
                  element={
                    <ContactTranslationsProvider locale={locale}>
                      <ContactUsPage />
                    </ContactTranslationsProvider>
                  }
                />

                <Route
                  path="/changepassword"
                  element={
                    <PrivateRoute
                      locale={locale}
                      render={(props) => (
                        <PasswordTranslationsProvider locale={locale}>
                          <ChangePasswordPage {...props} />
                        </PasswordTranslationsProvider>
                      )}
                    />
                  }
                  />

                <Route path="/rpolookup" element={<RpoLookupPage />} />

                <Route component={NotFound} />
              </Routes>
            </div>
            <LinksConsumer>
              {({ footerLinks }) => <AppFooter footerLinks={footerLinks} />}
            </LinksConsumer>
          </div>
        );
      }
    }
  )
);

const PrivateRoute = ({ render: componentRender, ...rest }) => (
      <UserConsumer>
        {({ user }) =>
          user !== null ? (
            componentRender(rest)
          ) : (
            <CommonTranslationsConsumer locale={rest.locale}>
              {({ commonTranslations }) => (
                <Alert type="error">
                  {commonTranslations
                    ? commonTranslations["ERROR_UNAUTHORIZED_PAGE"]
                    : ""}
                </Alert>
              )}
            </CommonTranslationsConsumer>
          )
        }
      </UserConsumer>
);

const RegionalRoute = ({ render: componentRender, validRegion, ...rest }) => (
      <RegionConsumer>
        {({ regionData }) => {
          const allowed = validRegion(regionData);
          return allowed ? (
            componentRender(rest)
          ) : (
            <CommonTranslationsConsumer locale={rest.locale}>
              {({ commonTranslations }) => (
                <Alert type="error">
                  {commonTranslations
                    ? commonTranslations["ERROR_REGION_UNAUTHORIZED_PAGE"]
                    : ""}
                </Alert>
              )}
            </CommonTranslationsConsumer>
          );
        }}
      </RegionConsumer>
);

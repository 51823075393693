import React from "react";
import "./Subscriptions.css";
import { Header } from "../UI";

class PanelExample extends React.Component {
  render() {
    const { header, text } = this.props;
    return (
      <div className="pb-sm-3">
        <Header level={1}>{header}</Header>
        <div className="text-gray">{text}</div>
      </div>
    );
  }
}

class PanelExample2 extends React.Component {
  render() {
    const { header, dangerouslySetInnerHTML } = this.props;
    return (
      <div className="pt-3 pb-sm-3">
        <Header level={2}>{header}</Header>
        <div
          className="text-gray"
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        ></div>
      </div>
    );
  }
}

export class SubscriptionsHeader extends React.Component {
  render() {
    const { header, text } = this.props;
    return (
      <div>
        <PanelExample header={header} text={text} />
      </div>
    );
  }
}

export class SubscriptionsHeader2 extends React.Component {
  render() {
    const { header, dangerouslySetInnerHTML } = this.props;
    return (
      <div>
        <PanelExample2
          header={header}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      </div>
    );
  }
}

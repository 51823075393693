import React from "react";
import { AlertMixin, AuthService, ProfileForm } from "tds_shared_ui";
import { HelperUtils, UserService } from "../services";
import { Alert, VerificationCodeModal } from "../components/UI";
import {
  WithCommonTranslations,
  WithRegion,
  WithUser,
  WithUserTranslations,
} from "../components/hocs";
import { TranslationMixin, withRouter } from "tds_shared_ui";

export const ProfilePage = withRouter(
  WithRegion(
    WithUser(
      WithCommonTranslations(
        WithUserTranslations(
          class ProfilePage extends AlertMixin(
            TranslationMixin(React.Component)
          ) {
            constructor(props) {
              super(props);

              this.alertRef = React.createRef();

              this.state = {
                states: [],
                loading: false,
                originalEmail: "",
                showVerification: false,
                verificationToken: null,
                alert: {
                  display: false,
                  type: "default",
                  message: "",
                },
                formValues: {
                  username: "",
                  password: "",
                  confirmPassword: "",
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  zipCode: "",
                  country: "",
                  locksmithID: "",
                  locksmithPasscode: "",
                  acdelcoAcctNum: "",
                  companyEmail: "",
                  vat: "",
                  companyName: "",
                  preferredLanguage: "",
                },
              };

              this.handleVerification = this.handleVerification.bind(this);
              this.handleHideVerification =
                this.handleHideVerification.bind(this);
            }

            componentDidMount() {
              if (this.props.user) {
                this.initializeUser(this.props.user);
              }
            }

            initializeUser(user) {
              let states = [];
              if (user === null) {
                user = {};
              }
              let address = user.address;
              if (address == null) {
                address = {};
              } else {
                if (address.country && this.props.countryStateMap) {
                  states = this.props.countryStateMap[address.country];
                }
              }

              this.setState({
                originalEmail: user.email,
                states: states,
                formValues: {
                  username: user.username || "",
                  password: "",
                  confirmPassword: "",
                  firstName: user.firstName || "",
                  middleName: user.middleName || "",
                  lastName: user.lastName || "",
                  email: user.email || "",
                  phone: user.phone || "",
                  addressLine1: address.addressLine1 || "",
                  addressLine2: address.addressLine2 || "",
                  city: address.city || "",
                  state: address.state || "",
                  zipCode: address.zipCode || "",
                  country: address.country || "",
                  locksmithID: user.locksmithID || "",
                  locksmithPasscode: "",
                  acdelcoAcctNum: user.acdelcoAcctNum || "",
                  companyEmail: user.companyEmail || "",
                  vat: user.vat || "",
                  companyName: user.companyName || "",
                  preferredLanguage: user.preferredLanguage || "",
                },
              });
            }

            componentDidUpdate(prevProps, prevState) {
              let { user } = this.props;
              if (user) {
                if (!prevProps || !prevProps.user) {
                  this.initializeUser(user);
                } else if (
                  prevProps.user == null ||
                  prevProps.user.address.country !== user.address.country
                ) {
                  this.setState({
                    states:
                      this.props.countryStateMap[this.props.user.address.country],
                  });
                } else if (prevProps.user && user) {
                  //check if they updated the language separately
                  const { preferredLanguage } = user;
                  if (prevProps.user.preferredLanguage !== preferredLanguage) {
                    this.state.formValues.preferredLanguage = preferredLanguage;
                  }
                }
              }
            }

            handleChange = (name, value, callback) => {
              this.setState((prevState) => {
                let newState = {};
                let formValues = Object.assign({}, prevState.formValues);
                formValues[name] = value;
                newState.formValues = formValues;
                if (
                  name === "country" &&
                  prevState.formValues.country !== value
                ) {
                  //reset state if country is changed
                  newState.formValues.state = "";
                  if (value != null) {
                    newState.states = this.props.countryStateMap[value];
                  } else {
                    newState.states = [];
                  }
                }
                return newState;
              }, callback);
            };

            handleCancel = () => {
              this.props.navigate(-1);
            };

            scrollToAlert() {
              HelperUtils.scrollToAlert(this.alertRef);
            }

            getUserObject(formValues) {
              let {
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
                country,
                confirmPassword,
                locksmithID,
                locksmithPasscode,
                ...otherProps
              } = formValues;

              let userUpdate = Object.assign({}, this.props.user, otherProps);

              //Password isn't updated through Edit Profile but appears on the
              //user object from props, remove it
              delete userUpdate.password;
              userUpdate.locksmithID = locksmithID;
              userUpdate.passcode = locksmithPasscode;
              let address = {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                country: country,
              };
              userUpdate.address = Object.assign(userUpdate.address, address);
              return userUpdate;
            }

            onValidationError = () => {
              this.setState(
                {
                  alert: {
                    display: true,
                    message: this.getTranslation("ERROR_FORM_VALIDATION"),
                    type: "error",
                  },
                },
                this.scrollToAlert
              );
            };

            handleVerification(verificationCode) {
              return this.updateUser(verificationCode).then((d) => {
                this.setState({
                  showVerification: false,
                });
              }).catch((e) => {
                //Adding empty catch to prevent webpack unhandled exception msg
              });
            }

            handleHideVerification() {
              this.setState({
                showVerification: false,
              });
            }

            handleSubmit = () => {
              this.setState({
                loading: true,
                alert: {
                  display: false,
                },
              });

              //check if email address has changed
              if (this.state.originalEmail !== this.state.formValues.email) {
                //request verification code to verify new email address
                AuthService.requestVerificationCodeForNewEmail(
                  this.props.user.username,
                  this.state.formValues.email,
                  this.state.formValues.preferredLanguage
                )
                  .then((d) => {
                    this.setState({
                      verificationToken: d.verificationToken,
                      showVerification: true,
                      loading: false,
                    });
                  })
                  .catch((e) => {
                    //they already have a token, allow them to enter it
                    if (
                      e.code === "1009" &&
                      e.category === "USER" &&
                      this.state.verificationToken
                    ) {
                      this.setState({
                        loading: false,
                        showVerification: true,
                      });
                    } else {
                      this.displayError(e, this.scrollToAlert);
                      this.setState({
                        loading: false,
                      });
                    }
                  });
              } else {
                this.updateUser();
              }
            };

            updateUser(verificationCode) {
              let user = this.getUserObject(this.state.formValues);
              if (verificationCode) {
                user.verificationCode = verificationCode;
                user.verificationToken = this.state.verificationToken;
              } else {
                delete user.verificationCode;
                delete user.verificationToken;
              }
              return UserService.updateUser(user)
                .then((data) => {
                  this.props.setUser(data);
                  this.setState(
                    {
                      loading: false,
                      alert: {
                        display: true,
                        message: this.getTranslation(
                          "MESSAGE_SUCCESS_UPDATE_PROFILE"
                        ),
                        type: "success",
                      },
                    },
                    this.scrollToAlert
                  );
                  this.initializeUser(data);
                })
                .catch((e) => {
                  this.displayError(e, this.scrollToAlert);
                  this.setState({
                    loading: false,
                  });
                });
            }

            render() {
              let showVATField = false;
              let showLocksmithFields = false;
              let requireState = false;
              let requireVAT = false;
              let taxIdLabelId = "";
              let regionCode = this.props.regionData.regionCode;
              if (this.props.regionData) {
                let { regionConfigs } = this.props.regionData;
                showVATField = regionConfigs.showVATField === "true";
                requireState = regionConfigs.stateRequired === "true";
                showLocksmithFields =
                  regionConfigs.showLocksmithFields === "true";
                requireVAT = regionConfigs.requireVAT === "true";
                taxIdLabelId = regionConfigs.taxIdLabelId;
              }

              return (
                <div className="registrationPage">
                  {this.state.alert.display && (
                    <Alert ref={this.alertRef} type={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}

                  {this.props.countries && (
                    <ProfileForm
                      submit={this.handleSubmit}
                      onValidationError={this.onValidationError}
                      readOnly={this.state.readOnly}
                      loading={this.state.loading}
                      formValues={this.state.formValues}
                      countries={this.props.countries}
                      states={this.state.states}
                      regionCode={regionCode}
                      requireState={requireState}
                      languages={this.props.languageOptions}
                      onChange={this.handleChange}
                      onCancel={this.handleCancel}
                      showTerms={false}
                      showLogin={false}
                      showVATField={showVATField}
                      taxIdLabelId={taxIdLabelId}
                      requireVAT={requireVAT}
                      showLocksmithFields={showLocksmithFields}
                    />
                  )}
                  <VerificationCodeModal
                    open={this.state.showVerification}
                    onCancel={this.handleHideVerification}
                    onSuccess={this.handleVerification}
                  />
                </div>
              );
            }
          }
        )
      )
    )
  )
);
